import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'

function Header({ setSelected }) {
	const [mode, setMode] = useState(undefined)

	useEffect(() => {
		const theme = localStorage.getItem('theme')
		setMode(theme ? theme : 'light')
	}, [])

	mode === 'dark' ? document.documentElement.classList.add('dark') : document.documentElement.classList.remove('dark')

	const switchTo = (mode) => {
		setMode(mode)
		localStorage.setItem('theme', mode)
	}
	return (
		<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='flex flex-col md:flex-row gap-3 justify-between text-mainlight dark:text-maindark'>
			<h1 className='text-left text-sm'>Rémy Boiré - Développeur créatif</h1>
			<div className='break-normal flex flex-row gap-2  md:justify-start'>
				<button
					className='text-xl relative'
					type='button'
					aria-label='Use Light Mode'
					onClick={() => switchTo('light')}
					onMouseEnter={() => setSelected({ title: 'light', model: 'light' })} //
					onMouseLeave={() => setSelected(null)}
				>
					JOUR
					{mode === 'light' && <Background />}
				</button>
				<button
					className='text-xl relative'
					type='button'
					aria-label='Use Dark Mode'
					onClick={() => switchTo('dark')}
					onMouseEnter={() => setSelected({ title: 'dark', model: 'dark' })} //
					onMouseLeave={() => setSelected(null)}
				>
					NUIT
					{mode === 'dark' && <Background />}
				</button>
			</div>
		</motion.div>
	)
}

export default Header

const Background = () => {
	return (
		<motion.div
			transition={{
				type: 'spring',
				damping: 20,
				stiffness: 200,
			}}
			layoutId='background'
			className='bg-mainlight dark:bg-maindark absolute inset-0'
		></motion.div>
	)
}
