import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { useRef } from 'react'
import { Color } from 'three'

function Model({ selected }) {
	const model = useGLTF('/models/scene.gltf')
	const group = useRef(null)

	useFrame((state) => {
		group.current.rotation.z = -Math.sin(state.clock.elapsedTime / 2) / 5 + Math.PI * 2
		group.current.rotation.y = state.clock.elapsedTime / 4
	})

	const theme = localStorage.getItem('theme')
	model.materials.main.color = theme === 'dark' ? new Color('#401d00') : new Color('#c86916')

	return (
		<>
			<directionalLight position={[10, 10, 0]} intensity={1.5} />
			<directionalLight position={[-10, 10, 5]} intensity={1} />
			<directionalLight position={[-10, 20, 0]} intensity={1.5} />
			<directionalLight position={[0, -10, 0]} intensity={0.25} />
			<group ref={group}>{selected?.model && <primitive object={model.nodes[selected.model]} scale='10' position={[0, 0, 0]} rotation={[0, 0, 0]} />}</group>
		</>
	)
}

export default Model
