import { Canvas } from '@react-three/fiber'
import Scene from './components/Scene'
import { Suspense } from 'react'

function App() {
	return (
		<div className='App'>
			<Suspense fallback={null}>
				<main className='w-full absolute inset-0 h-screen text-main dark:bg-bgdark bg-bglight'>
					<Canvas dpr={[1, 2]} flat fov={100}>
						<Scene />
					</Canvas>
				</main>
			</Suspense>
		</div>
	)
}

export default App
