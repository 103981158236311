import { Suspense, useState } from 'react'
import { Html } from '@react-three/drei'
import Model from './Model'
import projects from '../data.json'
import { deviceType } from 'detect-it'
import { motion } from 'framer-motion'
import Header from './Header'

function Scene() {
	const isTouch = deviceType !== 'mouseOnly'
	const [selected, setSelected] = useState(null)
	const [visited, setVisited] = useState([])
	const DELAY = 0.005
	const DURATION = 0.3
	return (
		<>
			<Suspense fallback={null}>
				<Model selected={selected} />
				<Html center wrapperClass='mix-blend-multiply dark:mix-blend-screen'>
					<div
						className={`p-2 sm:p-10 break-all
							text-[6vmin] leading-[145%]
							md:text-[3.75rem] md:leading-[1.45]
							w-screen h-screen flex flex-col
							select-none overflow-auto
							gap-6
							`}
					>
						<Header setSelected={setSelected} />
						<div>
							{projects.map((project, index) => {
								return (
									<motion.span
										initial={{ opacity: 0, y: 200 }}
										animate={{ opacity: 1, y: 0 }}
										transition={{
											delay: index * DELAY + 0.05,
											duration: DURATION,
										}}
										key={index}
									>
										{isTouch ? (
											<div className='flex justify-evenly items-center w-full'>
												<span
													className={`
												${!visited.includes(project) && 'bg-mainlight dark:bg-maindark'}
												text-mainlight dark:text-maindark cursor-pointer ${selected === project && 'bg-transparent dark:bg-transparent'}
												mr-auto py-3
												`}
													onTouchStart={() => setSelected(project)}
													onClick={() => setSelected(project)}
												>
													{project.title}&nbsp;
												</span>

												{selected === project && (
													<motion.a
														key={project.title}
														initial={{ opacity: 0, y: 10, scale: 1.5 }}
														animate={{ opacity: 1, y: 0, scale: 1 }}
														layoutId='link'
														// exit={{ opacity: 0, y: -10 }}
														className='ml-auto text-3xl px-3 py-1 bg-mainlight dark:bg-maindark text-bglight dark:text-bgdark'
														href={selected.link}
														target='_blank'
														rel='noreferrer'
														onClick={() => {
															setVisited((visited) => [...visited, selected.title])
														}}
													>
														<span className='inline-block -rotate-45'>-&gt;</span>
													</motion.a>
												)}
											</div>
										) : (
											<span
												className={`${!visited.includes(project.title) && 'bg-mainlight dark:bg-maindark'}
													text-mainlight dark:text-maindark hover:bg-transparent dark:hover:bg-transparent cursor-pointer`}
											>
												<a
													href={project.link}
													target='_blank'
													rel='noreferrer'
													onMouseEnter={() => setSelected(project)} //
													onMouseLeave={() => setSelected(null)}
													onClick={() => {
														setVisited((visited) => [...visited, project.title])
													}}
												>
													&nbsp;{project.title}&nbsp;
													<span className='inline-block -rotate-45'>-&gt;</span>&nbsp;
												</a>
												{/* {index + 1 < projects.length && <div className='bg-mainlight border-t-1 border-b-2 border-mainlight dark:border-maindark inline-block dark:bg-maindark w-[3px]  select-none text-transparent'>|</div>} */}
											</span>
										)}
									</motion.span>
								)
							})}
						</div>
					</div>
				</Html>
			</Suspense>
		</>
	)
}

export default Scene
